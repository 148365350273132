import React from "react";
import { Link } from "react-router-dom";
import navbarData from "../../../data/Navbar/navbar";
import { useTranslation } from "react-i18next";

function NavbarItem(props) {
  const { t } = useTranslation();
  return navbarData.map((item) => (
    <li
      key={item.id}
      className={item.subMenu || item.megaMenu ? "has-submenu" : ""}
    >
      <Link to={`${process.env.PUBLIC_URL + item.link}`}>{t(item.title)}</Link>
      {(() => {
        if (item.subMenu) {
          return (
            <ul className="submenu-nav">
              {item.subMenu.map((subItem, index) => {
                return (
                  <li key={index} className="has-submenu">
                    <a
                      href={`${process.env.PUBLIC_URL + subItem.link}`}
                      data-scroll
                      className="page-scroll"
                    >
                      {t(subItem.title)}
                    </a>
                    {subItem.subMenu ? (
                      <ul className="has-submenu">
                        {subItem.subMenu.map((subItem, index) => {
                          return (
                            <li key={index}>
                              <a
                                href={`${
                                  process.env.PUBLIC_URL + subItem.link
                                }`}
                                data-scroll
                                className="page-scroll"
                              >
                                {subItem.title}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    ) : null}
                  </li>
                );
              })}
            </ul>
          );
        }

        if (item.megaMenu) {
          return (
            <ul className="submenu-nav submenu-nav-mega">
              {item.megaMenu.map((megaItem, indx) => (
                <li key={indx} className="mega-menu-item">
                  <Link to={megaItem.link}>{megaItem.title}</Link>
                  <ul>
                    {megaItem.lists.map((listItem, idx) => {
                      return (
                        <li key={idx}>
                          <Link
                            to={`${process.env.PUBLIC_URL + listItem.link}`}
                          >
                            {listItem.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              ))}
            </ul>
          );
        }
      })()}
    </li>
  ));
}

export default NavbarItem;
