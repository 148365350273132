import React from "react";
import Feature from "./FeatureItem";
import FeaturesData from "../../data/Features/features";
import { useTranslation } from "react-i18next";
function Features({ classes }) {
  const { t } = useTranslation();
  return (
    <div className={`feature-area-wrapper ${classes}`}>
      <div className="container">
        <div className="row mtn-sm-60 mtn-md-5">
          {FeaturesData.map((feature) => (
            <Feature
              key={feature.id}
              title={t(feature.title)}
              text={t(feature.text)}
              img={feature.imgSrc}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Features;
