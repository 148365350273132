import React from "react";
import "./Navbar/style.css";
import LanguageSelect from "./../../languageSelect";

function HeaderConfig(props) {
  const MobileMenuHandler = () => {
    const offCanvasMenu = document.querySelector(".off-canvas-menu");
    offCanvasMenu.classList.add("active");
  };

  return (
    <div className="header-action text-right">
      <button className="btn-cog">
        <LanguageSelect />
      </button>

      <button onClick={MobileMenuHandler} className="btn-menu d-lg-none">
        <i className="fa fa-bars" />
      </button>
    </div>
  );
}

export default HeaderConfig;
