import React, { Fragment } from "react";
import Header from "../components/Header";
import BlogPage from "../templates/Blog";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import CallToAction from "../components/CallToAction";
import Content from "../components/UI/Content";
import Thumbnail from "../components/UI/Thumbnail";

const PageBlogGrid = () => {
  return (
    <Fragment>
      <Header />
      <BlogPage blog_type={"grid"} sidebar={false} sidebar_position={"left"} />
      {/*<div className="page-about-wrapper sm-top">
        <div className="container">
          <div className="row align-items-lg-center">
            <div className="col-md-6 col-lg-7 order-1">
              <Content classes="about-content ml-0">
                <h2>Portage salarial</h2>
                <p style={{ textAlign: "justify"}}>
                  Dédié à des consultants experts et autonomes, le portage
                  salarial permet à ses professionnels d’exercer une activité
                  indépendante en se déchargeant de la plupart des contraintes
                  liées à la création d’entreprise, le tout en disposant des
                  avantages du salariat. Une alternative avantageuse, pour les
                  consultants qui combine les avantages du salariat et
                  l’autonomie de l’entrepreneuriat. Notre force est de proposer
                  des solutions de portage salarial flexibles et optimisées.
                </p>
              </Content>
            </div>

            <div className="col-md-6 col-lg-5 order-0 order-md-1">
              <Thumbnail
                classes="about-thumb"
                imgSrc={require("../assets/img/service/05.jpg")}
              />
            </div>
          </div>
        </div>
      </div>
*/}
      <CallToAction />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageBlogGrid;
