import React from "react";
import SectionTitle from "../../UI/SectionTitle/indexdigital";
import SlickSlider from "../../UI/Slick";
import Member from "./Member";
import { useTranslation } from "react-i18next";
import teamBg from "../../../assets/img/team/team-bg.jpg";
import Teams from "../../../data/Team/home-one";

function Team() {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    loop: false,
    dots: true,
    className: "team-content-wrap slick-dots--light mtn-md-5",
    responsive: [
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const { t } = useTranslation();
  return (
    <div
      className="team-area-wrapper bg-img sp-y"
      style={{ backgroundImage: `url(${teamBg})` }}
    >
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-4">
            <SectionTitle
              variant="light"
              heading={t("home_digital_title")}
              text={t("home_digital_description")}
            />
          </div>

          <div className="col-lg-8">
            <SlickSlider settings={settings}>
              {Teams.map((team) => (
                <div key={team.id}>
                  <Member
                    id={team.id}
                    name={team.name}
                    profilePic={team.profilePic}
                  />
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
