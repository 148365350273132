import React, { Fragment } from "react";
import Navbar from "../components/navbar";
import Header from "../components/Header";
import Slider from "../components/Slider/home-one";
/*import About from "../components/About/home-one";*/
import Features from "../components/Features";
import Services from "../components/Services";
import Team from "../components/Team/home-one";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";

import Info from "../components/Info";

import Footer from "../components/Footer";

import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";

const HomeOne = () => {
  return (
    <Fragment>
      <Header />
      <Slider />
      {/*<About />*/}
      <Features classes="sp-top" />

      <Services classes="sm-top-wt" />
      <br />
      <br />
      <Team />
      <Blog />
      <BrandLogo />

      <Info />
      <br />
      <br />
      <Footer />
      <MobileMenu />
      <LoginRegister />
    </Fragment>
  );
};

export default HomeOne;
