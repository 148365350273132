import React from "react";
import {
  Table,
  Modal,
  Space,
  Popconfirm,
  message,
  DatePicker,
  Row,
  Col,
  Form,
  Input,
} from "antd";
import annonceActions from "../../redux/annonces/actions";
import { connect } from "react-redux";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "moment/locale/fr";
import moment from "moment";
import { Spin } from "antd";
class Tableg extends React.Component {
  constructor(props) {
    super(props);
    this.props.getallannonces();
    this.state = {
      visible: false,
      poste: "",
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  renderannonces = () => {
    const data = this.props.annonces;
   
    const tableData = data.map((row) => ({
      key: row.id,
      id: row.id,
      poste: row.poste,
      localisation: row.localisation,
      dateajout: row.dateajout.date,
      datedeb: row.datedeb.date,
      typemlpoi: row.typemlpoi,
      salaire: row.salaire,
    }));
    return (
      <Table
        size="small"
        scroll={{ x: "calc(600px + 30%)" }}
        columns={this.columns}
        dataSource={tableData}
      />
    );
  };

  render() {
   
    this.columns = [
      {
        title: "Poste",
        dataIndex: "poste",
        key: "poste",
        render: (text) => <a>{text}</a>,
      },
      {
        title: "Type de contrat",
        dataIndex: "typemlpoi",
        key: "typemlpoi",
      },
      {
        title: "Salaire",
        dataIndex: "salaire",
        key: "salaire",
      },
      {
        title: "Localisation",
        dataIndex: "localisation",
        key: "localisation",
      },

      {
        title: "Date d'ajout",
        dataIndex: "dateajout",
        key: "dateajout",
        render(data) {
          return (
            <span>
              {moment(data).format("Do MMM YYYY")}
              <br />
            </span>
          );
        },
      },
      {
        title: "Action",
        key: "action",
        render: (text, data) => (
          <Space size="middle">
            <Link to={"/gannonces/" + data.id}>
              <EditOutlined style={{ color: "green" }} />
            </Link>

            <Popconfirm
              title={`etes vous sur de vouloir supprimer ${data.poste}?`}
              onConfirm={() => (
                this.props.deleteannonce(data.id),
                setTimeout(
                  function () {
                    this.props.getallannonces();
                    message.error(`l'annonce : ${data.poste} est supprimée`);
                  }.bind(this),
                  3000
                )
              )}
            >
              {
                <a>
                  <DeleteOutlined style={{ color: "red" }} />
                </a>
              }
            </Popconfirm>
          </Space>
        ),
      },
    ];
    return this.props.loading ? (
      <div
        style={{
          textAlign: "center",
          // background: "rgba(0, 0, 0, 0.05)",
          borderRadius: "4px",
          marginBottom: "20px",
          padding: " 30px 50px",
          margin: "20px 0",
        }}
      >
        <Spin />
      </div>
    ) : (
      this.renderannonces()
    );
  }
}

const { getallannonces, deleteannonce } = annonceActions;

function mapStateToProps(state) {
  const { annonces, loading } = state.Annonce.toJS();
  return {
    annonces,
    loading,
  };
}

export default connect(mapStateToProps, { getallannonces, deleteannonce })(
  Tableg
);
