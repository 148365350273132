import React from "react";
import "./style.css";
import { Layout, Menu } from "antd";
import TableG from "./../Datatable";
import logo from "../../assets/img/logo.svg";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import authAction from "../../redux/auth/actions";
import annonceActions from "../../redux/annonces/actions";
import { Link } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UploadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "moment/locale/fr";
import moment from "moment";
const key = "updatable";
const { Option } = Select;
const { Header, Sider, Content } = Layout;

class SiderDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      visible: false,
      poste: "",
      localisation: "",
      datedeb: "",
      description: "",
      salaire: "",
      experience: "",
      fiche: "",
      taches: "",
      typedemlpoi: "",
    };
    //this.handleChange = this.handleChange.bind(this);
  }
  onChangeselect = (value) => {
    this.setState({ typedemlpoi: value });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  onCloseSubmit = () => {
    this.setState({
      visible: false,
    });
    this.openMessageadd();
    this.postannonce();
  };
  openMessageadd = () => {
    message.loading({ content: "Chargement...", key });

    window.location.reload();
  };

  onChange = (date, dateString) => {
    // console.log(date, dateString);
    this.setState({ datdeb: dateString });
  };
  onChangeinput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    //  console.log(e.target.value);
  };
  postannonce = () => {
    this.props.postannonce({
      poste: this.state.poste,
      localisation: this.state.localisation,
      datedeb: this.state.datdeb,
      description: this.state.description,
      salaire: this.state.salaire,
      experience: this.state.experience,
      fiche: this.state.fiche,
      taches: this.state.taches,
      typedemlpoi: this.state.typedemlpoi,
    });
  };

  render() {
    console.log("this.props", this.props);
    return (
      <Layout style={{ minHeight: "1100px" }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo">
            <img
              src={logo}
              alt="JPG-Logo"
              style={{
                margin: "15px 0",

                padding: "0 5px",
              }}
            />
          </div>

          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<UploadOutlined />}>
              Les annonces
            </Menu.Item>

            <Menu.Item
              key="2"
              icon={<UserOutlined />}
              onClick={this.props.logout}
            >
              Déconnexion
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ paddingRight: 0 }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              // minHeight: 1100,
              overflow: "auto",
            }}
          >
            <Button
              type="primary"
              onClick={this.showDrawer}
              style={{ float: "right" }}
            >
              <PlusOutlined /> Annonce
            </Button>

            <Drawer
              title="Créer une nouvelle anonnce"
              width={"30%"}
              onClose={this.onClose}
              visible={this.state.visible}
              bodyStyle={{ paddingBottom: 80 }}
              footer={
                <div
                  style={{
                    textAlign: "right",
                  }}
                >
                  <Button onClick={this.onClose} style={{ marginRight: 8 }}>
                    Annuler
                  </Button>
                  <Button onClick={this.onCloseSubmit} type="primary">
                    Ajouter
                  </Button>
                </div>
              }
            >
              <Form layout="vertical" hideRequiredMark>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="poste"
                      label="Poste"
                      rules={[
                        { required: true, message: "Tapez le titre du poste " },
                      ]}
                    >
                      <Input
                        placeholder="Tapez le titre du poste"
                        value={this.state.poste}
                        onChange={this.onChangeinput}
                        name="poste"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="localisation"
                      label="Localisation"
                      rules={[
                        { required: true, message: "Tapez la localisation" },
                      ]}
                    >
                      <Input
                        placeholder="Tapez la localisation"
                        onChange={this.onChangeinput}
                        name="localisation"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Experience :"
                      name="experience"
                      rules={[
                        { required: true, message: "Tapez l'experience" },
                      ]}
                    >
                      <Input
                        placeholder="Tapez l'expérience "
                        name="experience"
                        id="experience"
                        onChange={this.onChangeinput}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Salaire:"
                      name="salaire"
                      rules={[{ required: true, message: "Tapez le salaire" }]}
                    >
                      <Input
                        placeholder="Tapez le salaire"
                        name="salaire"
                        id="salaire"
                        onChange={this.onChangeinput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label="Date de debut prévue:"
                      name="datedeb"
                      rules={[
                        { required: true, message: "Tapez la date de début" },
                      ]}
                    >
                      <DatePicker
                        name="datedeb"
                        id="datedeb"
                        onChange={this.onChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Type d'emlpoi:">
                      <Select
                        name="typedemlpoi"
                        id="typedemlpoi"
                        onChange={this.onChangeselect}
                      >
                        <Select.Option value="CDI">CDI</Select.Option>
                        <Select.Option value="CDI de chantier">
                          CDI de chantier
                        </Select.Option>
                        <Select.Option value="CDD">CDD</Select.Option>

                        <Select.Option value="Stage">Stage</Select.Option>
                        <Select.Option value="Freelance">
                          Freelance
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item label="Description">
                      <Input.TextArea
                        placeholder="Tapez la descripiton du poste"
                        name="description"
                        id="description"
                        rows={4}
                        onChange={this.onChangeinput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item label="Fiche">
                      <Input.TextArea
                        placeholder="Tapez la fiche du poste"
                        name="fiche"
                        id="fiche"
                        rows={4}
                        onChange={this.onChangeinput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col span={24}>
                    <Form.Item label="Tâches">
                      <Input.TextArea
                        placeholder="Tapez les tâches du poste"
                        name="taches"
                        id="taches"
                        rows={4}
                        onChange={this.onChangeinput}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Drawer>
            <br />
            <br />
            <TableG />
          </Content>
        </Layout>
      </Layout>
    );
  }
}
const { getallannonces, postannonce } = annonceActions;
const { logout } = authAction;
function mapStateToProps(state) {
  const { annonces, loading } = state.Annonce.toJS();
  const { isAuthenticated, user } = state.Auth.toJS();
  return {
    annonces,
    loading,
    isAuthenticated,
    user,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getallannonces,
    postannonce,
    logout,
  })(SiderDemo)
);
