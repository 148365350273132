import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import actions from "./actions";
import {
  getallannonces,
  postannonce,
  deleteannonceapi,
  getannonceapi,
  putannonceapi,
  candidatureapi,
  postmail,
} from "./../services";

//get all annonces
export function* getannonces(props) {
  yield takeEvery(actions.GET_ALL_ANNONCES_REQUEST, function* (action) {
    try {
      const response = yield call(getallannonces);

      yield put({ type: actions.GET_ALL_ANNONCES_SUCCESS, annonces: response });
    } catch (error) {
      yield put({ type: actions.GET_ALL_ANNONCES_ERROR, error });
    }
  });
}
//post annonce
export function* postannonceapi(props) {
  yield takeEvery("POST_ANNONCE_REQUEST", function* (action) {
    try {
      yield call(postannonce, action.payload);

      yield [put({ type: actions.POST_ANNONCE_SUCCESS })];
      window.location.reload();
    } catch (error) {
      yield put({ type: actions.POST_ANNONCE_ERROR, error });
    }
  });
}
//delete annonce
export function* deleteannonce(props) {
  yield takeEvery(actions.DELETE_ANNONCE_REQUEST, function* (action) {
    try {
      yield call(deleteannonceapi, action.id);
      yield [put({ type: actions.DELETE_ANNONCE_SUCCESS })];
    } catch (error) {
      yield put({ type: actions.DELETE_ANNONCE_ERROR, error });
    }
  });
}
//get annonce
export function* getannonce(props) {
  yield takeEvery(actions.GET_ANNONCE_REQUEST, function* (action) {
    try {
      const response = yield call(getannonceapi, action.id);
      yield put({ type: actions.GET_ANNONCE_SUCCESS, annonce: response });
    } catch (error) {
      yield put({ type: actions.GET_ANNONCE_ERROR, error });
    }
  });
}
//put annonce
export function* putannonce(props) {
  yield takeEvery("PUT_ANNONCE_REQUEST", function* (action) {
    try {
      const response = yield call(putannonceapi, action.payload);
      yield put({ type: actions.PUT_ANNONCE_SUCCESS, annonce: response });
      //window.location.reload();
    } catch (error) {
      yield put({ type: actions.PUT_ANNONCE_ERROR, error });
    }
  });
}
//post candidature
export function* postcandidatureapi(props) {
  yield takeEvery("POST_CANDIDATURE_REQUEST", function* (action) {
    try {
      yield call(candidatureapi, action.payload);

      yield put({ type: actions.POST_CANDIDATURE_SUCCESS });
    } catch (error) {
      yield put({ type: actions.POST_CANDIDATURE_ERROR, error });
    }
  });
}
//send mail
export function* postmailapi(props) {
  yield takeEvery("POST_MAIL_REQUEST", function* (action) {
    try {
      yield call(postmail, action.payload);

      yield put({ type: actions.POST_MAIL_SUCCESS });
    } catch (error) {
      yield put({ type: actions.POST_MAIL_ERROR, error });
    }
  });
}
export default function* rootSaga() {
  yield all([
    fork(getannonces),
    fork(postannonceapi),
    fork(deleteannonce),
    fork(getannonce),
    fork(putannonce),
    fork(postcandidatureapi),
    fork(postmailapi),
  ]);
}
