import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import { clearToken } from "../../helpers/utility";
import actions from "./actions";
import { loginUserService } from "./../services";
import jwt_decode from "jwt-decode";
export function* loginRequest(props) {
  yield takeEvery("LOGIN_REQUEST", function* (action) {
    try {
      clearToken();
      const response = yield call(loginUserService, action.payload);

      var accessTokenObj = localStorage.getItem("id_token");
      const decoded = jwt_decode(accessTokenObj);

      yield put({
        type: actions.LOGIN_SUCCESS,
        token: response.token,
        user: decoded,
      });
    } catch (error) {
      yield put({ type: actions.LOGIN_ERROR, error });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery("LOGIN_SUCCESS", function* (payload) {
    clearToken();
    yield localStorage.setItem("id_token", payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* () {});
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
    yield put(push("/"));
    clearToken();
    localStorage.removeItem("id_token");
    window.location.reload();
  });
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
  ]);
}
