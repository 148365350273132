import axios from "axios";
//import jwt_decode from "jwt-decode";
import setAuthToken from "../helpers/setAuthToken";
const apiHost = "https://www.jpgconsulting-partners.com/backend/public";
//
export const loginUserService = (userData) => {
  const LOGIN_API_ENDPOINT = `${apiHost}/login`;

  return axios
    .post(LOGIN_API_ENDPOINT, userData)
    .then((res) => {
      localStorage.setItem("id_token", res.data.token);
      setAuthToken(res.data.token);
      //const decoded = jwt_decode(res.data.token);
      return res.data;
      //console.log(decoded);
    })
    .catch((err) => {
      console.log("ERR add 😱 ", err);
    });
};
// get all annonces
export function getallannonces() {
  //return the actual action to do
  const GET_ALL_ANNONCES_API_ENDPOINT = `${apiHost}/annonces`;

  return axios
    .get(GET_ALL_ANNONCES_API_ENDPOINT, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((resp) => {
      // console.log("RESP ADD 😍", resp);
      return resp.data;
    })
    .catch((err) => {
      console.log("ERR add 😱 ", err);
    });
}
//post annonce
export const postannonce = ({
  poste,
  localisation,
  datedeb,
  description,
  typedemlpoi,
  experience,
  salaire,
  fiche,
  taches,
}) => {
  const POST_ANNONCE_API_ENDPOINT = `${apiHost}/api/annonce`;
  try {
    axios
      .post(
        POST_ANNONCE_API_ENDPOINT,
        {
          poste,
          localisation,
          datedeb,
          description,
          typedemlpoi,
          experience,
          salaire,
          fiche,
          taches,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("id_token"),
          },
        }
      )
      .then((resp) => {
        //console.log("RESP ADD 😍", resp);
      })
      .catch((err) => {
        console.log("ERR add 😱 ", err);
      });
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
};
//delete annonce
export const deleteannonceapi = (id) => {
  const DELETE_ANNONCE_API_ENDPOINT = `${apiHost}/api/deleteannonce` + id;
  try {
    axios
      .delete(DELETE_ANNONCE_API_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        },
      })
      .then((resp) => {
        console.log("RESP ADD", resp);
      })
      .catch((err) => {
        console.log("ERR add", err);
      });
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
};
// put annonce

export const putannonceapi = ({
  id,
  poste,
  localisation,
  datedeb,
  description,
  typedemlpoi,
  experience,
  salaire,
  fiche,
  taches,
}) => {
  const PUT_ANNONCE_API_ENDPOINT = `${apiHost}/api/putannonce` + id;
  try {
    axios
      .put(
        PUT_ANNONCE_API_ENDPOINT,
        {
          poste,
          localisation,
          datedeb,
          description,
          typedemlpoi,
          experience,
          salaire,
          fiche,
          taches,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + localStorage.getItem("id_token"),
          },
        }
      )
      .then((resp) => {
        // console.log("update", resp);
      })
      .catch((err) => {
        console.log("ERR add", err);
      });
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
};

// get annonce
export function getannonceapi(id) {
  //return the actual action to do

  const GET_ANNONCE_API_ENDPOINT = `${apiHost}/api/getannonce` + id;

  return axios
    .get(GET_ANNONCE_API_ENDPOINT, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      },
    })
    .then((resp) => {
      //  console.log("RESP ADD", resp.data);
      return resp.data;
    })
    .catch((err) => {
      console.log("ERR add", err);
    });
}
//post candidature
export const candidatureapi = ({ data, id }) => {
  const POST_ANNONCE_API_ENDPOINT = `${apiHost}/postuler` + id;
  try {
    axios
      .post(POST_ANNONCE_API_ENDPOINT, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((resp) => {
        // console.log("RESP ADD 😍", resp);
      })
      .catch((err) => {
        console.log("ERR add 😱 ", err);
      });
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
};

//post mail
export const postmail = (data) => {
  console.log(data, "9999");
  const POST_MAIL_API_ENDPOINT = `${apiHost}/sendmail`;
  try {
    axios
      .post(POST_MAIL_API_ENDPOINT, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((resp) => {
        console.log("RESP ADD 😍", resp);
      })
      .catch((err) => {
        console.log("ERR add 😱 ", err);
      });
  } catch (e) {
    console.log(`😱 Axios request failed: ${e}`);
  }
};
