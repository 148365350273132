import React from "react";
import "./style.css";
import { connect } from "react-redux";
import authAction from "../redux/auth/actions";
import { Route, withRouter } from "react-router-dom";
import { BlockOutlined } from "@ant-design/icons";
class Input extends React.Component {
  render() {
    return (
      <div>
        <input
          type={this.props.type}
          id={this.props.id}
          className="form-control"
          placeholder={this.props.text}
          value={this.props.value}
          onChange={this.props.onChange}
          required=""
          style={{}}
        />
      </div>
    );
  }
}

class Image extends React.Component {
  render() {
    return (
      <div>
        <img
          className="mb-4"
          src={this.props.src}
          alt=""
          width="200"
          height="60"
          style={{ display: "Block", margin: "0 auto" }}
        />
      </div>
    );
  }
}

class Header extends React.Component {
  render() {
    return (
      <div>
        <h1 className="h6 mb-3 font-weight-normal">{this.props.text}</h1>
      </div>
    );
  }
}

class Button extends React.Component {
  render() {
    return (
      <div>
        <button
          className="btn btn-lg btn-primary btn-block"
          type="button"
          onClick={this.props.onClick}
        >
          {this.props.placeholder}
        </button>
      </div>
    );
  }
}

class RegisterScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      signIn: false,
      reload: true,
    };
  }

  onChangeEmail = (event) => {
    // console.log(event.target.value);
    this.setState({
      username: event.target.value,
    });
  };

  onChangePassWord = (event) => {
    // console.log(event.target.value);
    this.setState({
      password: event.target.value,
    });
  };
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated) {
      this.props.history.push("/gannonces");
    } else {
      //this.props.history.push("/202009-JPG-MP/");
    }
  }

  login = () => {
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  };
  handleSubmit = (e) => {
    this.login();
  };
  onClick = (event) => {
    // event.preventdefault();
    // console.log(event.target);
    this.setState({
      signIn: true,
    });

    if (this.state.email !== "" && this.state.password !== "") {
      this.setState({
        reload: false,
      });

      setTimeout(() => {
        this.setState({
          reload: true,
        });
      }, 5000);
    } else {
      this.login();
    }
  };

  render() {
    const { username, password, signIn, reload } = this.state;
    const altertStyle = {
      color: "red",
    };
    return (
      <React.Fragment>
        <div className="bodyform">
          <form className="form-signin login">
            <a href="/">
              <Image src="https://www.jpgconsulting-partners.com/backend/public/logo-jpg.png" />
            </a>
            <Header text="Bienvenue dans votre espace admin!" />
            <Input
              id="inputEmail"
              text="username"
              type="text"
              value={username}
              onChange={this.onChangeEmail}
            />
            <Input
              id="inputPassword"
              text="Password"
              type="password"
              value={password}
              onChange={this.onChangePassWord}
            />
            <br />
            <Button
              placeholder="Se connecter "
              onClick={() => this.handleSubmit()}
            />

            <div className="off-canvas-item mt-sm-30">
              <div className="copyright-content">
                &copy; {new Date().getFullYear()}{" "}
                <a
                  href="http://www.msit-conseil.fr/"
                  target={"_blank"}
                  rel="noopener noreferrer"
                >
                  MSIT Conseil
                </a>
                . Tous les droits sont réservés.
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
const { login } = authAction;

function mapStateToProps(state) {
  const { isAuthenticated, user } = state.Auth.toJS();
  return {
    isAuthenticated,
    user,
  };
}

export default connect(mapStateToProps, { login })(withRouter(RegisterScreen));
