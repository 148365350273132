import React, { Component } from "react";
import Sectionbloctitle from "./sectiontitleblog";
import BlogItem from "./blogItem";
import SlickSlider from "../UI/Slick";
import Blogs from "../../data/Blog/blog";
import { connect } from "react-redux";
import annonceActions from "../../redux/annonces/actions";
import { ConnectedRouter } from "react-router-redux";

import "moment/locale/fr";
import moment from "moment";
class Blog extends Component {
  constructor(props) {
    super(props);
    this.props.getallannonces();

    this.state = {
      posts: [],
      currentPage: 1,
      postsPerPage: 6,
    };
  }

  render() {
    const settings = {
      dots: true,
      infinite: false,
      accessibility: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      className: "testimonial-content--3 testimonial-grid",
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 415,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 401,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <div className="blog-area-wrapper sm-top">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <Sectionbloctitle />
            </div>
          </div>

          <SlickSlider settings={settings}>
            {!this.props.loading &&
              this.props.annonces
                .reverse()
                .slice(0, 11)
                .map((annonce) => (
                  <BlogItem
                    key={annonce.id}
                    id={annonce.id}
                    //thumb="blog/08.jpg"
                    title={annonce.poste}
                    excerpt={annonce.description.slice(0, 100) + "..."}
                    postBy={moment(annonce.dateajout.date)}
                    date={annonce.datedeb.date}
                    localisation={annonce.localisation}
                  />
                ))}
          </SlickSlider>
        </div>
      </div>
    );
  }
}
const { getallannonces } = annonceActions;

function mapStateToProps(state) {
  const { annonces, loading } = state.Annonce.toJS();

  return {
    annonces,
    loading,
  };
}

export default connect(mapStateToProps, {
  getallannonces,
})(Blog);
