import React, { Component } from "react";
import FormInput from "../../components/UI/Input";
import { connect } from "react-redux";
import { Button } from "antd";
import annonceActions from "../../redux/annonces/actions";
import {
  UploadOutlined,
  UserOutlined,
  MailOutlined,
  PhoneOutlined,
  ProfileOutlined,
} from "@ant-design/icons";

class Postuler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nom: "",
      numero: "",
      email: "",
      lettre: "",
      file: "",
      id: this.props.idannonce,
    };
  }
  onCloseSubmit = () => {
    this.postcandidature();
  };
  onChangeinput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };

  onChangeupload = (e) => {
    this.setState({ file: e.target.files[0] });
    // console.log(e.target.files[0]);
  };
  postcandidature = () => {
    const data = new FormData();
    data.append("file", this.state.file);
    data.append("nom", this.state.nom);
    data.append("email", this.state.email);
    data.append("numero", this.state.numero);
    data.append("lettre", this.state.lettre);

    this.props.postcandidature({ data: data, id: this.state.id });
  };
  render() {
    // console.log(this.props, "44");
    return (
      <div className="contact-form-wrap">
        <form id="contact-form">
          <div className="row">
            <div className="col-md-6">
              <UserOutlined
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "35px",

                  zIndex: 1,
                }}
              />
              <FormInput
                tag="input"
                type="text"
                name="nom"
                placeholder="Name*"
                value={this.state.nom}
                onChange={this.onChangeinput}
                required
                classes="pl-5"
              />
            </div>
            <div className="col-md-6">
              <MailOutlined
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "35px",
                  zIndex: 1,
                }}
              />
              <FormInput
                tag={"input"}
                type={"email"}
                name={"email"}
                placeholder={"  Email *"}
                value={this.state.email}
                onChange={this.onChangeinput}
                required
                classes="pl-5"
              />
            </div>
            <div className="col-md-6">
              <PhoneOutlined
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "35px",
                  zIndex: 1,
                }}
              />
              <FormInput
                tag={"input"}
                type={"text"}
                name={"numero"}
                placeholder={"Cell number"}
                value={this.state.numero}
                onChange={this.onChangeinput}
                required
                classes="pl-5"
              />
            </div>
            <div className="col-md-6">
              <UploadOutlined
                style={{
                  position: "absolute",
                  top: "23px",
                  left: "18px",
                  zIndex: 1,
                }}
              />
              <FormInput
                tag={"file"}
                type={"file"}
                name="file"
                placeholder={"cv *"}
                onChange={this.onChangeupload}
              // value={this.state.file}
              />
            </div>

            <div className="col-12">
              <ProfileOutlined
                style={{
                  position: "absolute",
                  top: "20px",
                  left: "35px",
                  zIndex: 1,
                }}
              />
              <FormInput
                tag={"textarea"}
                name={"lettre"}
                placeholder={"Cover letter*"}
                value={this.state.lettre}
                onChange={this.onChangeinput}
                required
                classes="pl-5"
              />
              <Button
                type="primary"
                //   className="btn-outline"
                onClick={() => this.onCloseSubmit()}
              >
                Apply
              </Button>

              <div className="form-message" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const { postcandidature } = annonceActions;

export default connect(null, { postcandidature })(Postuler);
