import React from "react";

const FormInput = ({
  tag,
  type,
  name,
  placeholder,
  classes,
  onChange,
  value,
  onClick,
  required,
}) => {
  return (
    <div className="single-input-item">
      <label>
        {(() => {
          if (tag === "input") {
            return (
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                className={classes}
                onChange={onChange}
                value={value}
                required
              />
            );
          } else if (tag === "textarea") {
            return (
              <textarea
                name={name}
                cols="30"
                rows="5"
                placeholder={placeholder}
                className={classes}
                onChange={onChange}
                value={value}
                required
              />
            );
          } else if (tag === "file") {
            return (
              <input
                type="file"
                name={name}
                className={classes}
                onChange={onChange}
                value={value}
                required
              />
            );
          } else if (tag === "button") {
            return (
              <button className={`btn-outline ${classes}`} type="submit" >
                Envoyer le message
              </button>
            );
          } else if (tag === "buttoncv") {
            return (
              <button className={`btn-outline ${classes}`} onClick={onClick} type="submit">
                Postuler
              </button>
            );
          }
        })()}
      </label>
    </div>
  );
};

export default FormInput;
