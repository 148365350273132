import React from "react";
import Content from "../../UI/Content";
import Thumbnail from "../../UI/Thumbnail";
import ScrollableAnchor from "react-scrollable-anchor";

const About = ({ title, heading, content, thumb, id }) => {
  return (
    <section id={id}>
      <div className="page-about-wrapper ssp-top">
        <div className="container">
          <div className="row align-items-lg-center">
            <div className="col-md-6 col-lg-5 order-0 order-md-1">
              <Thumbnail classes="about-thumb" imgSrc={thumb} />
            </div>
            <div className="col-md-6 col-lg-7 order-1">
              <Content classes="about-content ml-0">
                <h2>{title}</h2>
                <p
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{ textAlign: "justify" }}
                />
              </Content>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
