const annonceActions = {
  GET_ALL_ANNONCES_REQUEST: "GET_ALL_ANNONCES_REQUEST",
  GET_ALL_ANNONCES_SUCCESS: "GET_ALL_ANNONCES_SUCCESS",
  GET_ALL_ANNONCES_ERROR: "GET_ALL_ANNONCES_ERROR",
  getallannonces: () => ({ type: annonceActions.GET_ALL_ANNONCES_REQUEST }),
  POST_ANNONCE_REQUEST: "POST_ANNONCE_REQUEST",
  POST_ANNONCE_SUCCESS: "POST_ANNONCE_SUCCESS",
  POST_ANNONCE_ERROR: "POST_ANNONCE_ERROR",
  postannonce: (payload) => ({
    type: annonceActions.POST_ANNONCE_REQUEST,
    payload,
  }),
  DELETE_ANNONCE_REQUEST: "DELETE_ANNONCE_REQUEST",
  DELETE_ANNONCE_SUCCESS: "DELETE_ANNONCE_SUCCESS",
  DELETE_ANNONCE_ERROR: "DELETE_ANNONCE_ERROR",
  deleteannonce: (id) => ({ type: annonceActions.DELETE_ANNONCE_REQUEST, id }),
  GET_ANNONCE_REQUEST: "GET_ANNONCE_REQUEST",
  GET_ANNONCE_SUCCESS: "GET_ANNONCE_SUCCESS",
  GET_ANNONCE_ERROR: "GET_ANNONCE_ERROR",
  getannonce: (id) => ({ type: annonceActions.GET_ANNONCE_REQUEST, id }),
  PUT_ANNONCE_REQUEST: "PUT_ANNONCE_REQUEST",
  PUT_ANNONCE_SUCCESS: "PUT_ANNONCE_SUCCESS",
  PUT_ANNONCE_ERROR: "PUT_ANNONCE_ERROR",
  putannonce: (payload) => ({
    type: annonceActions.PUT_ANNONCE_REQUEST,
    payload,
  }),
  POST_CANDIDATURE_REQUEST: "POST_CANDIDATURE_REQUEST",
  POST_CANDIDATURE_SUCCESS: "POST_CANDIDATURE_SUCCESS",
  POST_CANDIDATURE_ERROR: "POST_CANDIDATURE_ERROR",
  postcandidature: (payload) => ({
    type: annonceActions.POST_CANDIDATURE_REQUEST,
    payload,
  }),
  POST_MAIL_REQUEST: "POST_MAIL_REQUEST",
  POST_MAIL_SUCCESS: "POST_MAIL_SUCCESS",
  POST_MAIL_ERROR: "POST_MAIL_ERROR",
  postmail: (payload) => ({
    type: annonceActions.POST_MAIL_REQUEST,
    payload,
  }),
};







export default annonceActions;
