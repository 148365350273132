import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import About from "../components/About/home-one";
import "./styleabout.css";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import Features from "../components/Features";
import Info from "../components/Info";
import Map from "../components/Map";
import { useTranslation } from "react-i18next";

const PageAbout = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/agence.jpg")}
        title={t("about_title")}
        content={t("about_header_title")}
      />
      {/*<h1>{t("hello_welcome_to_react")}</h1>*/}
      <About />

      <Features classes={"sm-top"} />
      <Funfact />
      <div className={`feature-area-wrapper`}>
        <div className="container-fluid">
          <div className="row">
            {/* bloc 1*/}
            <div
              className="col-xs-12 col-md-6 col-lg-4 offset-lg-1"
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row"
                  style={{ marginLeft: "1px", marginRight: "1px" }}
                >
                  <div
                    className="col-md-6 content"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="content-overlay  icon iconsabout">
                      <i
                        className="fa fa-tachometer fa-3"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <img
                      src={require("../assets/img/service/01.jpg")}
                      className="imgspeech"
                      alt="..."
                    />
                  </div>
                  <div
                    className="col-md-6 "
                    style={{
                      paddingLeft: "1px",
                      paddingRight: "1px",
                      borderLeft: "none",
                    }}
                  >
                    <div className="card-body speech d-none d-sm-block">
                      <h6 className="titlesabout">
                        {t("about_feature_1_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_1_description")}
                      </p>
                    </div>
                  </div>
                  <div className="card-body speechtop d-block d-sm-none">
                    <h6 className="titlesabout">
                      {t("about_feature_1_title")}
                    </h6>
                    <p className="textabout">
                      {t("about_feature_1_description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* bloc 2*/}
            <div
              className="col-xs-12 col-md-6 col-lg-4"
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row"
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                  }}
                >
                  <div
                    className="col-md-6 content"
                    style={{
                      paddingLeft: "1px",
                      paddingRight: "1px",
                      borderLeft: "none",
                    }}
                  >
                    <div className="content-overlay  icon iconsabout">
                      <i
                        className="fa fa-calculator fa-3"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <img
                      src={require("../assets/img/service/02.jpg")}
                      className="imgspeech"
                      alt="..."
                    />
                  </div>
                  <div
                    className="col-md-6 speech d-none d-sm-block"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="card-body speech d-none d-sm-block">
                      <h6 className="titlesabout">
                        {t("about_feature_2_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_2_description")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 speech d-block d-sm-none"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="card-body speechtop d-block d-sm-none">
                      <h6 className="titlesabout">
                        {t("about_feature_2_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_2_description")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* bloc 3 (1/2) image */}
            <div
              className="col-xs-12 col-md-3 col-lg-2"
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row"
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                  }}
                >
                  <div
                    className="col-md-12 content"
                    style={{
                      paddingLeft: "1px",
                      paddingRight: "1px",
                      borderLeft: "none",
                    }}
                  >
                    <div className="content-overlay icon iconsabout">
                      <i
                        className="fa fa-line-chart fa-3"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <img
                      src={require("../assets/img/service/07.jpg")}
                      className="imgspeech"
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* bloc 4*/}
            <div
              className="col-xs-12 col-md-6 col-lg-4 order-4 order-lg-4 order-md-6 order-sm-6 "
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row "
                  style={{ marginLeft: "1px", marginRight: "1px" }}
                >
                  <div
                    className="col-md-6 order-1 order-lg-1 order-sm-2 "
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="card-body speechright d-none d-sm-block">
                      <h6 className="titlesabout">
                        {" "}
                        {t("about_feature_3_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_3_description")}
                      </p>
                    </div>

                    <div className="card-body speechtop d-block d-sm-none">
                      <h6 className="titlesabout">
                        {" "}
                        {t("about_feature_3_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_3_description")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 content  order-lg-2 order-sm-1"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="content-overlay  icon iconsabout">
                      <i className="fa fa-rss fa-3" aria-hidden="true"></i>
                    </div>
                    <img
                      className="imgspeech"
                      src={require("../assets/img/service/05.jpg")}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* bloc 5*/}
            <div
              className="col-xs-12 col-md-6 col-lg-4 offset-lg-1"
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row"
                  style={{ marginLeft: "1px", marginRight: "1px" }}
                >
                  <div
                    className="col-md-6"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="card-body speechright d-none d-sm-block">
                      <h6 className="titlesabout">
                        {t("about_feature_4_title")}
                      </h6>
                      <p className="align-middle textabout">
                        {t("about_feature_4_description")}
                      </p>
                    </div>
                    <div className="card-body speechtop d-block d-sm-none">
                      <h6 className="titlesabout">
                        {t("about_feature_4_title")}
                      </h6>
                      <p className="textabout">
                        {t("about_feature_4_description")}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6 content"
                    style={{ paddingLeft: "1px", paddingRight: "1px" }}
                  >
                    <div className="content-overlay  icon iconsabout">
                      <i
                        className="fa fa-file-text fa-3"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <img
                      className="imgspeech"
                      src={require("../assets/img/service/06.jpg")}
                      alt="..."
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* bloc 3 (2/2) description  */}
            <div
              className="col-xs-12 col-md-2 order-lg-6 order-md-4 order-sm-4 "
              style={{ paddingLeft: "1px", paddingRight: "1px" }}
            >
              <div className="cardabout mb-6">
                <div
                  className="row"
                  style={{
                    marginLeft: "1px",
                    marginRight: "1px",
                  }}
                >
                  <div className="card-body speechtop">
                    <h6 className="titlesabout">
                      {t("about_feature_5_title")}
                    </h6>
                    <p className="textabout">
                      {t("about_feature_5_description")}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BrandLogo />
      <Map />
      <Info classes="sp-top" />
      <br />
      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
