import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";

const languageMap = {
  fr: { label: "Français", active: false, flag: "sl-flag flag-fr", lab: "fr" },
  en: { label: "English", active: true, flag: "sl-flag flag-usa", lab: "en" },
  es: {
    label: "español",
    active: false,
    flag: "sl-flag flag-esp",
    lab: "es",
  },
  de: {
    label: "Deutsche",
    active: false,
    flag: "sl-flag flag-de",
    lab: "du",
  },
};

const LanguageSelect = () => {
  const selected = localStorage.getItem("i18nextLng") || "fr";
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <div className="d-flex justify-content-end align-items-center language-select-root">
      <Button
        onClick={({ currentTarget }) => setMenuAnchor(currentTarget)}
        style={{ paddingTop: "10px" }}
      >
        <div className="header-action" style={{ marginRight: "7px" }}>
          {languageMap[selected].lab}
        </div>

        <i className="fa fa-globe header-action" />
      </Button>
      <Popover
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div>
          <List style={{ borderRadius: "0", top: "111px !important" }}>
            {Object.keys(languageMap)?.map((item) => (
              <ListItem
                button
                key={item}
                onClick={() => {
                  i18next.changeLanguage(item);
                  setMenuAnchor(null);
                }}
              >
                <i class={languageMap[item].flag}></i>
                {languageMap[item].label}
              </ListItem>
            ))}
          </List>
        </div>
      </Popover>
    </div>
  );
};

export default LanguageSelect;
