import React from "react";
import { useTranslation } from "react-i18next";
const PageHeader = ({ bgImg, title, content }) => {
  const { t } = useTranslation();
  return (
    <div
      className="page-header-area bg-img"
      style={{ backgroundImage: `url(${bgImg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 col-xl-8 m-auto text-center">
            <div className="page-header-content-inner">
              <div className="page-header-content">
                <h2>{t(title)}</h2>
                <p>{t(content)}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
