import React from "react";
import Text from "../UI/Text";
import Widget from "../UI/Widget";
import List from "../UI/List";
import LI from "../UI/List/Item";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo-dark.svg";
import ContactInfo from "../../templates/Contact/ContactInfo";
import { useTranslation } from "react-i18next";
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer-area sp-bottom">
      <div className="container">
        <div className="row mtn-40">
          <div className="col-lg-5">
            <div className="widget-item">
              <div className="about-widget">
                <Link to={`${process.env.PUBLIC_URL + "/"}`}>
                  <img src={Logo} alt="Logo JPG" title="Logo JPG" />
                </Link>

                <Text>{t("footer_description")}</Text>
              </div>
            </div>
          </div>

          <div className=" col-lg-2 ">
            <Widget title={t("footer_socials")}>
              <List classes="widget-list">
                <LI>
                  <a
                    href="https://www.linkedin.com/company/jpg-consulting-partners/"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className={`fa fa-linkedin`} /> Linkedin
                  </a>
                </LI>
                <LI>
                  <a
                    href="https://twitter.com/PartnersJpg"
                    target={"_blank"}
                    rel="noopener noreferrer"
                  >
                    <i className={`fa fa-twitter`} /> Twitter
                  </a>
                </LI>
              </List>
            </Widget>
          </div>

          <div className=" col-lg-5">
            <Widget title={t("footer_contact_us")}>
              <address>
                <i className="fa fa-map-marker icons" /> Boulevard Georges
                Favon, 43, 1611 Genève <br />
                <i className="fa fa-phone icons" /> (+41) 51 23 00 00
                <br />
                <i className="fa fa-envelope-o icons" />
                gestion@jpgconsulting-partners.com
                <br />
              </address>
            </Widget>
          </div>
        </div>
        <Text classes="copyright-txt">
          &copy; {new Date().getFullYear()}{" "}
          <a
            href="https://www.msit-conseil.fr/"
            target={"_blank"}
            rel="noopener noreferrer"
          >
            MSIT Conseil
          </a>
          . {t("footer_copyright")}
        </Text>
      </div>
    </footer>
  );
}

export default Footer;
