import React from "react";
import { useTranslation } from "react-i18next";
import SectionTitle from "../UI/SectionTitle";
const Sectionbloctitle = () => {
  const { t } = useTranslation();
  return (
    <SectionTitle title={t("opportunities_title")} heading={t("offre_des")} />
  );
};

export default Sectionbloctitle;
