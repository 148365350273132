import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "antd/dist/antd.css";
/*
 * @ All pages Import
 */

import HomeOne from "./pages/HomeOne";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import BlogGridWithoutSidebar from "./pages/BlogGridWithoutSidebar";
import BlogDetailsPage from "./pages/BlogDetails";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Error404 from "./pages/Error404";
import ScrollToTop from "./helpers/ScrollToTop";
import Gannonces from "./pages/Gannonces";
import Edit from "./components/Edit";
import Login from "./pages/login";
import "./i18nextInit";
/*
 * @ protected and public route
 */
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

const App = () => {
  return (
    <Router>
      <Provider store={store}>
        <ScrollToTop>
          <Switch>
            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/"}`}
              component={HomeOne}
            />

            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/services"}`}
              component={Service}
            />
            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/service/:serviceID"}`}
              component={ServiceDetails}
            />

            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/admin"}`}
              component={Login}
            />
            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/annonces"}`}
              component={BlogGridWithoutSidebar}
            />

            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/blog/:blogID"}`}
              component={BlogDetailsPage}
            />

            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/about"}`}
              component={About}
            />

            <Route
              restricted={false}
              exact
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              component={Contact}
            />
            <PrivateRoute
              exact
              path={`${process.env.PUBLIC_URL + "/gannonces"}`}
              component={Gannonces}
            />
            <PrivateRoute
              exact
              path={`${process.env.PUBLIC_URL + "/gannonces/:annonceID"}`}
              component={Edit}
            />
            <PublicRoute exact component={Error404} />
          </Switch>
        </ScrollToTop>
      </Provider>
    </Router>
  );
};

export default App;
