import React from "react";
import SlickSlider from "../../components/UI/Slick";
import List from "../../components/UI/List";

import Text from "../../components/UI/Text";

import Postuler from "./formcv";
import { useTranslation } from "react-i18next";
const ServiceContentWrap = ({
  service,
  totalService,
  prevService,
  nextService,
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-8">
      <div className="service-details-content">
        <div className="service-thumbnail-wrapper">
          <SlickSlider
            settings={{
              className: "service-details-thumb",
              arrows: false,
              dots: true,
            }}
          ></SlickSlider>
        </div>

        <div className="service-details-info">
          <h3>{service.poste}</h3>
          <p>{service.description}</p>

          <div className="about-service mt-50 mt-sm-35">
            <h4>{t('ficheposte')}</h4>
            {<div dangerouslySetInnerHTML={{ __html: service.fiche }} />}
            <List classes="service-feature mt-30">
              {/*service.features.map((feature, indx) => (
                <LI key={indx}>{feature}</LI>
              ))*/}
            </List>
          </div>

          <div className="how-we-works-wrap mt-50 mt-sm-35">
            <h4>{t('principauxtaches')} </h4>
            <Text styles={{ marginBottom: 20 }}>{service.taches}</Text>
            {/*<div className="how-we-works-content service-details-page">
              <div className="row">
                {HowWeWorksData.map((iconbox) => (
                  <IconBox
                    key={iconbox.id}
                    icon={iconbox.icon}
                    title={iconbox.title}
                    text={iconbox.text}
                  />
                ))}
              </div>
            </div>*/}
          </div>
          <Postuler idannonce={service.id} />
          <div className="pagination-wrap">
            <ul className="pagination">
              <li className="prev">
                <a
                  href={`${
                    process.env.PUBLIC_URL +
                    `/service/${prevService.poste
                      .split(" ")
                      .join("-")
                      .toLocaleLowerCase()}?id=${prevService.id}`
                    }`}
                  className={service.id === 1 ? "disabled" : null}
                >
                  <i className="fa fa-long-arrow-left" /> Previous
                </a>
              </li>
              <li className="next">
                <a
                  href={`${
                    process.env.PUBLIC_URL +
                    `/service/${nextService.poste
                      .split(" ")
                      .join("-")
                      .toLocaleLowerCase()}?id=${nextService.id}`
                    }`}
                  className={service.id === totalService ? "disabled" : null}
                >
                  Next <i className="fa fa-long-arrow-right" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceContentWrap;
