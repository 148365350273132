import React from "react";


function Navbar(props) {
  return (
    <nav class="navbar navbar-expand-lg ">
  

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="main-menu nav">
      <li class="nav-item active">
        <a class="nav-link" href={`${process.env.PUBLIC_URL + "/"}`}>Home </a>
      </li>
      <li class="nav-item" >
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="#">Link</a>
      </li>
      
      <li class="nav-item">
        <a class="nav-link disabled" href="#">Disabled</a>
      </li>
    </ul>
    
  </div>
</nav>
  );
}

export default Navbar;