import React from "react";
import SlickSlider from "../../UI/Slick";
import SliderData from "../../../data/Slider/home-1";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const NextArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-right" />
    </button>
  );
};

const PrevArrow = ({ className, onClick }) => {
  return (
    <button className={className} onClick={onClick}>
      <i className="fa fa-angle-left" />
    </button>
  );
};

const Slider = () => {
  const { t } = useTranslation();
  const settings = {
    arrows: true,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className={"slider-area"}>
      <SlickSlider settings={settings}>
        {SliderData.map((item) => (
          <div key={item.id}>
            <div
              className="slider-item"
              style={{
                backgroundImage: `url(${require("../../../assets/img/" +
                  item.bg)})`,
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-xl-7">
                    <div className="slider-content">
                      {item.id == 4 ? (
                        <div>
                          <h3 style={{ color: "white" }}>{t(item.title)}</h3>
                          <p style={{ color: "white" }}>{t(item.text)}</p>
                        </div>
                      ) : (
                        <div>
                          <h3>{t(item.title)}</h3>
                          <p>{t(item.text)}</p>
                        </div>
                      )}

                      <Link
                        to={`${process.env.PUBLIC_URL + item.btnLink}`}
                        className="btn btn-brand"
                      >
                        {t(item.btnText)}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </SlickSlider>
    </div>
  );
};
export default Slider;
