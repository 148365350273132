import React, { Fragment } from "react";
import Layout from "../components/Layout";

const GAnnonces = () => {
  return (
    <Fragment>
      <Layout />
    </Fragment>
  );
};

export default GAnnonces;
