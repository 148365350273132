import React, { Fragment, Component } from "react";
import PageHeader from "../../components/PageHeader";
import PageWrapper from "../../components/PageWrapper";
import ServiceContentWrap from "./ServiceContentWrap";
import { connect } from "react-redux";
import annonceActions from "../../redux/annonces/actions";
import Sidebar from "../../components/Sidebar";
import SidebarItem from "../../components/Sidebar/SidebarItem";
import List from "../../components/UI/List";
import LI from "../../components/UI/List/Item";
import "moment/locale/fr";
import label from "../../data/Services/title.json";
import moment from "moment";
import { withTranslation } from 'react-i18next';
import {
  EuroOutlined,
  FileDoneOutlined,
  SettingOutlined,
  FieldTimeOutlined,
  AuditOutlined,
} from "@ant-design/icons";

class ServiceDetails extends Component {
  constructor(props) {
    super(props);
    this.props.getallannonces();

    this.state = {
      posts: [],
    };
  }

  getItems() {
    !this.props.loading && this.setState({ posts: this.props.annonces });
  }
  componentDidMount() {
    this.getItems();
  }

  render() {
    const { t } = this.props;
    const ServicesData = this.props.annonces;

    const serviceID = new URLSearchParams(window.location.search).get("id");
    const service =
      !this.props.loading &&
      ServicesData.find(
        (serviceItem) => serviceItem.id === parseInt(serviceID)
      );
    const currentService = !this.props.loading && ServicesData.indexOf(service);
    let prevService;
    let nextService;
    currentService === 0
      ? (prevService = ServicesData[currentService])
      : (prevService = ServicesData[currentService - 1]);
    currentService + 1 === ServicesData.length
      ? (nextService = ServicesData[currentService])
      : (nextService = ServicesData[currentService + 1]);

    return (
      !this.props.loading && (
        <Fragment>
          <PageHeader
            bgImg={require("../../assets/img/page-header.jpg")}
            title={service.poste}
          />

          <PageWrapper classes="sm-top service-details-wrapper">
            <Sidebar classes={"col-lg-4 mtn-40"}>
              <SidebarItem title={t("Details")} >
                <List classes="service-list">
                  <LI key={service.id}>
                    <EuroOutlined style={{ fontSize: "20px" }} />{" "}
                    {service.salaire.replace(/(.)(?=(\d{3})+$)/g, "$1 ")}
                  </LI>
                  <LI key="m">
                    {" "}
                    <FileDoneOutlined
                      style={{ fontSize: "20px", fontWeight: "500px" }}
                    />
                    <span style={{ fontWeight: "700px" }}>
                      {" "}
                      {service.typemlpoi}
                    </span>
                  </LI>
                  <LI key="z">
                    {" "}
                    <SettingOutlined style={{ fontSize: "20px" }} />{" "}
                    {service.experience}
                  </LI>

                  <LI key="g">
                    {" "}
                    <FieldTimeOutlined style={{ fontSize: "20px" }} />{" "}
                    {moment(service.datedeb.date).format("Do MMM YYYY")}
                  </LI>
                </List>
              </SidebarItem>
              <SidebarItem title={t("autre")}>
                <List classes="service-list">
                  {!this.props.loading &&
                    this.props.annonces
                      .reverse()
                      .slice(0, 4)
                      .map((serviceItem) => (
                        <LI key={serviceItem.id}>
                          <a
                            href={`${
                              process.env.PUBLIC_URL +
                              `/service/${serviceItem.poste
                                .split(" ", "/")
                                .join("-")
                                .toLowerCase()}?id=${serviceItem.id}`
                              }`}
                          >
                            {serviceItem.poste}
                          </a>
                        </LI>
                      ))}
                </List>
              </SidebarItem>
            </Sidebar>
            <ServiceContentWrap
              service={service}
              totalService={ServicesData.length}
              nextService={nextService}
              prevService={prevService}
            />
          </PageWrapper>
        </Fragment>
      )
    );
  }
}
const { getallannonces } = annonceActions;

function mapStateToProps(state) {
  const { annonces, loading } = state.Annonce.toJS();

  return {
    annonces,
    loading,
  };
}

export default connect(mapStateToProps, { getallannonces, })(withTranslation()(ServiceDetails));
