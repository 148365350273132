import { Map } from "immutable";
import jwt_decode from "jwt-decode";
import actions from "./actions";
import { message } from "antd";

if (localStorage.getItem("id_token")) {
  var accessTokenObj = localStorage.getItem("id_token");
  var user = jwt_decode(accessTokenObj);
  // console.log(user);
} else var user = null;

let initState = new Map({
  isAuthenticated: false,
  user: accessTokenObj ? user : {},
  token: accessTokenObj ? accessTokenObj : "",
});

export default function authReducer(state = initState, action) {
  //console.log(action);
  switch (action.type) {
    //LOGIN

    case actions.LOGIN_REQUEST:
      return state.set("loading", true);
    case actions.LOGIN_SUCCESS:
      return state
        .set("token", action.token)
        .set("user", action.user)
        .set("isAuthenticated", true)
        .set("loading", false);
    case actions.LOGIN_ERROR:
      message.error({
        content: "Mot de passe ou username invalide ",
        duration: 2,
      });
      return state.set("loading", false).set("isAuthenticated", false);
    case actions.LOGOUT:
      return state
        .set("token", null)
        .set("user", null)
        .set("isAuthenticated", false);

    default:
      return state;
  }
}
