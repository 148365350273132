import React, { Component } from "react";
import FormInput from "../../components/UI/Input";
import { connect } from "react-redux";
import { Button, Select } from "antd";
import annonceActions from "../../redux/annonces/actions";
import { translate } from "react-i18next";
import "./style.css";
const { Option } = Select;
const children = [];
class SendMail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nom: "",
      numero: "",
      email: "",
      lettre: "",
      site: "",
      file: "",
    };
  }

  onCloseSubmit = () => {
    this.state.nom &&
      this.state.email &&
      this.state.lettre &&
      this.state.site &&
      this.postmail();
  };
  onChangeinput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };
  onChangeupload = (e) => {
    this.setState({ file: e.target.files[0] });
  };
  handleChange = (e) => {
    this.setState({ site: e.target.value });
    console.log(e.target.value);
  };
  postmail = () => {
    const data = new FormData();
    data.append("file", this.state.file);
    data.append("nom", this.state.nom);
    data.append("email", this.state.email);
    data.append("numero", this.state.numero);
    data.append("lettre", this.state.lettre);
    data.append("site", this.state.site);
    this.props.postmail(data);
  };

  render() {
    return (
      <div className="contact-form-wrap">
        <form id="contact-form" name="contact-form">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                tag="input"
                type="text"
                name="nom"
                placeholder={"Name"}
                value={this.state.nom}
                onChange={this.onChangeinput}
                required
              />
            </div>

            <div className="col-md-6">
              <FormInput
                tag={"input"}
                type={"email"}
                name={"email"}
                placeholder={"  Email *"}
                value={this.state.email}
                onChange={this.onChangeinput}
                required
              />
            </div>

            <div className="col-md-6">
              <FormInput
                tag={"input"}
                type={"text"}
                name={"numero"}
                placeholder={"Number"}
                value={this.state.numero}
                onChange={this.onChangeinput}
                required
              />
            </div>
            <div className="col-md-6">
              {/*<Select onChange={this.handleChange} placeholder={"Site JPG"}>{children}</Select>*/}

              <select
                name="site"
                id="site-select"
                className="ant-select"
                onChange={this.handleChange}
                required
              >
                <option value="">Please select</option>
                <option key="Geneve" value="info@jpgconsulting-partners.com">
                  JPG-Genève
                </option>
                ,
                <option
                  key="Londres"
                  value="info-uk@jpgconsulting-partners.com"
                >
                  JPG-Londres
                </option>
                ,
                <option
                  key="Paris"
                  value="info-france@jpgconsulting-partners.com"
                >
                  JPG-Paris
                </option>
                ,
                <option
                  key="Luxembourg"
                  value="info-lux@jpgconsulting-partners.com"
                >
                  JPG-Luxembourg
                </option>
                ,
                <option
                  key="E.A.U"
                  value="info-Dubaï@jpgconsulting-partners.com"
                >
                  JPG-E.A.U
                </option>
                ,
                <option
                  key="Maroc"
                  value="info-maroc@jpgconsulting-partners.com"
                >
                  JPG-Maroc
                </option>
              </select>
            </div>
            <div className="col-md-12">
              <FormInput
                tag={"file"}
                type="file"
                name="file"
                onChange={this.onChangeupload}
              />
            </div>

            <div className="col-12">
              <FormInput
                tag={"textarea"}
                name={"lettre"}
                placeholder={"Comment"}
                value={this.state.lettre}
                onChange={this.onChangeinput}
                required
              />

              <Button
                onClick={() => this.onCloseSubmit()}
                className="btn-outline-send "
              >
                Send
              </Button>

              <div className="form-message" />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const { postmail } = annonceActions;

export default connect(null, { postmail })(SendMail);
