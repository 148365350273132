import React from "react";
import NavbarItem from "./NavbarItem";

function Navbar(props) {
  return (
    <ul className="main-menu nav" style={{ padding: "10px 10px" }}>
      <NavbarItem
        className="nav-link"
        activeClass="active"
        onClick={() => this.props.onClick()}
      />
    </ul>
  );
}

export default Navbar;
