import { Map } from "immutable";
import actions from "./actions";
import { message } from "antd";
let initState = new Map({
  loading: true,
  annonces: {},
  annonce: {},
  load: true,
});
export default function annonceReducer(state = initState, action) {
  switch (action.type) {
    case actions.POST_ANNONCE_SUCCESS:
      return message.success({
        content: "Les données de l'annonce sont enregistrées!",

        duration: 2,
      });
    case actions.GET_ALL_ANNONCES_REQUEST:
      return state.set("loading", true);
    case actions.GET_ALL_ANNONCES_SUCCESS:
      return state.set("annonces", action.annonces).set("loading", false);
    case actions.GET_ALL_ANNONCES_ERROR:
      return state.set("loading", false);
    case actions.GET_ANNONCE_REQUEST:
      return state.set("load", true);
    case actions.GET_ANNONCE_SUCCESS:
      return state.set("annonce", action.annonce).set("load", false);
    case actions.GET_ANNONCE_ERROR:
      return state.set("load", false);
    case actions.PUT_ANNONCE_REQUEST:
      return state.set("loading", true);
    case actions.PUT_ANNONCE_SUCCESS:
      return state.set("annonce", action.annonce).set("loading", false);
    case actions.PUT_ANNONCE_ERROR:
      return state.set("loading", false);
    case actions.POST_CANDIDATURE_SUCCESS:
      message.success({
        content:
          "Votre candidature est enregistrée avec succées, veuillez consulter votre boite mail ",
        duration: 3,
      });
    case actions.POST_MAIL_SUCCESS:
      message.success({
        content: "Votre message est enregistré avec succées",
        duration: 3,
      });

    default:
      return state;
  }
}
