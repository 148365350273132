import React from "react";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import CallToActionData from "../../data/CallToAction/call-to-action";
import { Link } from "react-router-dom";

function CallToAction() {
  const { t } = useTranslation();
  return (
    <div className="call-top-action-wrap sp-y">
      <div className="container">
        <div className="footer-top-content">
          <div className="row align-items-center">
            <div className="col-md-8">
              <h2>{t(CallToActionData.title)}</h2>
              <p>{parse(t(CallToActionData.text))}</p>
            </div>
            <div className="col-md-4 text-md-right mt-sm-25">
              <Link
                to={`${process.env.PUBLIC_URL + CallToActionData.btnLink}`}
                className="btn-outline"
              >
                {t(CallToActionData.btnText)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
