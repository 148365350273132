import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import getToken from "./../../helpers/utility";
import authAction from "../../redux/auth/actions";
import { Route, withRouter } from "react-router-dom";
class SignIn extends Component {
  constructor() {
    super();

    this.state = {
      username: "",
      password: "",
    };
  }

  handleChange(e) {
    //console.log("handle change called");
    this.setState({ [e.target.id]: e.target.value });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAuthenticated) {
      this.props.history.push("/gannonces");
    } else {
      //this.props.history.push("/202009-JPG-MP/");
    }
  }

  login = () => {
    this.props.login({
      username: this.state.username,
      password: this.state.password,
    });
  };
  handleSubmit = (e) => {
    this.login();
  };

  render() {
    return (
      <div>
        <div className="off-canvas-item">
          <div className="log-in-content-wrap">
            <h2>Login</h2>
            <div className="login-form mtn-15">
              <form onSubmit={this.handleSubmit}>
                <div className="form-input-item">
                  <label htmlFor="username" className="sr-only">
                    Username
                  </label>
                  <input
                    type="text"
                    id="username"
                    placeholder="Username"
                    required
                    onChange={
                      (this.handleChange = this.handleChange.bind(this))
                    }
                    value={this.state.username}
                  />
                </div>

                <div className="form-input-item">
                  <label htmlFor="password" className="sr-only">
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    required
                    onChange={
                      (this.handleChange = this.handleChange.bind(this))
                    }
                    value={this.state.password}
                  />
                </div>
                <div className="form-input-item">
                  <Button
                    type="primary"
                    className="btn-submit"
                    onClick={() => this.handleSubmit()}
                  >
                    Se connecter
                  </Button>
                </div>
                <br />
                <br />
              </form>
            </div>
          </div>
        </div>

        <div className="off-canvas-item mt-sm-30">
          <div className="copyright-content">
            &copy; {new Date().getFullYear()}{" "}
            <a
              href="http://www.msit-conseil.fr/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              MSIT Conseil
            </a>
            . Tous les droits sont réservés.
          </div>
        </div>
      </div>
    );
  }
}

const { login } = authAction;

function mapStateToProps(state) {
  const { isAuthenticated, user } = state.Auth.toJS();
  return {
    isAuthenticated,
    user,
  };
}

export default connect(mapStateToProps, { login })(withRouter(SignIn));
