import React from "react";
import "./Marker.css";
import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
const Marker = (props) => {
  const { color, text, id, desc, email, tel, title } = props;

  return (
    <div>
      <span className="marker" id={`popover-${text}`}></span>
      <UncontrolledPopover
        className="cluster-info"
        trigger="legacy"
        placement="top"
        target={`popover-${text}`}
      >
        <PopoverHeader>
          <a to={`/branch/${text}`}>
            <b>{text}</b>
          </a>
        </PopoverHeader>
        <PopoverBody>
          <b>{title}</b>
          <br />
          <small>{desc}</small>
          <br />
          <small>{tel}</small>
          <br />
          <small>{email}</small>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  );
};

export default Marker;
