import React from "react";
import { Link } from "react-router-dom";
import "moment/locale/fr";
import moment from "moment";
import { LinkedinOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
function BlogItem(props) {
  const { t } = useTranslation();
  const serviceURL = `/service/${props.title
    .split("/")
    .join("-")
    .toLocaleLowerCase()}?id=${props.id}`;
  return (
    <div className={props.cols ? props.cols : null}>
      <div className="blog-item">
        {props.thumb ? (
          <figure className="blog-thumb">
            <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
              <img
                src={require("../../assets/img/" + props.thumb)}
                alt={props.title}
              />
            </Link>
          </figure>
        ) : null}
        <div className="blog-content">
          <h2 className="h5">
            <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
              {props.title}
            </Link>
          </h2>
          <p style={{ textAlign: "justify" }}>
            {props.excerpt.slice(0, 100) + "..."}
          </p>

          <div className="blog-meta">
            <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
              {t("Localisation")}: {props.localisation}
            </Link>
          </div>
          <div className="blog-meta">
            <Link to={`${process.env.PUBLIC_URL + serviceURL}`}>
              {t("Date")} : {moment(props.date).format("Do MMM YYYY")}
            </Link>
            <Button
              type="primary"
              style={{
                background: "#0A66C2",
                borderColor: "#0A66C2",
              }}
              icon={<LinkedinOutlined style={{ fontSize: "20px" }} />}
            >
              <a
                href={`http://www.linkedin.com/shareArticle?mini=true&url=www.jpgconsulting-partners.com${
                  process.env.PUBLIC_URL + serviceURL
                }`}
                target="_blank"
              >
                <span
                  style={{ padding: "5px", fontSize: "15px", color: "#fff" }}
                >
                  {t("Partager")}
                </span>
              </a>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogItem;
