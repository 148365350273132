import React from "react";
import From from "./From";
import { useTranslation } from "react-i18next";
const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <div className={"contact-page-area-wrapper sp-y"} id={"contact"}>
      <div className="container">
        <div className="contact-content-wrap">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-form-area contact-method">
                <h3>{t("msg_contact")}</h3>
                <From />
              </div>
            </div>

            <div className="col-lg-5">
              <div className="contact-information contact-method address">
                <div className="contact-info-con">
                  <h3>Contact info</h3>
                  <address>
                    <div className="mt-3">
                      <b>Genève</b>
                      <p>
                        <i className="fa fa-map-marker icons" /> Boulevard
                        Georges Favon, 43, 1611 Genève <br />
                        <i className="fa fa-envelope-o icons" />{" "}
                        info@jpgconsulting-partners.com <br />
                        <i className="fa fa-phone icons" /> (+41) 51 23 00 00
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>Londres :</b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                        info-uk@jpgconsulting-partners.com
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>Paris : </b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                        info-france@jpgconsulting-partners.com
                      </p>
                    </div>
                    <div className="mt-3">
                      <b> Luxembourg : </b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                        info-lux@jpgconsulting-partners.com
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>E.A.U :</b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                        info-dubaï@jpgconsulting-partners.com
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>Maroc :</b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                        info-maroc@jpgconsulting-partners.com
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>Lisbonne :</b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                      </p>
                    </div>
                    <div className="mt-3">
                      <b>Miami :</b>
                      <p>
                        <i className="fa fa-envelope-o icons" />
                      </p>
                    </div>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
