import React, { Component } from "react";
import logo from "../../assets/img/logo.svg";

class Logo extends Component {
  render() {
    return (
      <div className="logo-area logonav">
        <a href="/">
          <img
            src={logo}
            alt="JPG-Logo"
            className="logo-area"
            style={{ height: "50px" }}
          />
        </a>
      </div>
    );
  }
}

export default Logo;
