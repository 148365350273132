import React from "react";
import CallToActionData from "../../data/CallToAction/call-to-action";

import funfactbg from "../../assets/img/fun-fact-bg.jpg";
import { useTranslation } from "react-i18next";
function Info() {
  const { t } = useTranslation();
  return (
    <div className="fun-fact-area parallax">
      <div className="container">
        <div className="footer-top-content">
          <div className="row align-items-center">
            <div className="col-md-8 ">
              <h4 style={{ color: "rgba(255,255,255,0.8)", zIndex: 1 }}>
                <strong>{t("home_info_title")} </strong>
                <br />{" "}
              </h4>
              <p style={{ color: "rgba(255,255,255,0.8)", zIndex: 1 }}>
                {" "}
                {t("home_info_description")}
              </p>
            </div>
            <div className="col-md-4 text-md-right mt-sm-25">
              <a
                href="/contact"
                className="btn-outline"
                style={{
                  color: "rgba(255,255,255,0.8)",
                  borderColor: "rgba(255,255,255,0.6)",
                  zIndex: 1,
                }}
              >
                {t("footer_contact_us")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Info;
