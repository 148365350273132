import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import PageAbout from "../components/About/page";
import PageAboutReverse from "../components/About/pagereverse";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import ServicesData from "../data/Services/services";
import { Nav, Tab } from "react-bootstrap";
import "./styleservices.css";
import LI from "../components/UI/List/Item";
import parse from "html-react-parser";
import Thumbnail from "../components/UI/Thumbnail";
import Content from "../components/UI/Content";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import aboutData from "../data/About/home-one";
import SmoothScroll from "smooth-scroll";
export const scroll = new SmoothScroll('a[href*="#"]:not([href="#"])', {
  speed: 1000,
  speedAsDuration: true,
});

const PageService = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/metiers.jpg")}
        title={t("trades_title")}
        content={t("trades_desc")}
      />
      <section id="BFI">
        <div className="about-area-wrapper ssp-top">
          <div className="container">
            <div className="row align-items-lg-center">
              <div className="col-md-6 col-lg-6">
                <Thumbnail
                  classes="about-thumb"
                  imgSrc={require("../assets/img/service/02.jpg")}
                />
              </div>

              <div className="col-md-6 col-lg-6">
                <Content>
                  <h2>{t("trades_1_title")}</h2>

                  <p style={{ textAlign: "justify" }}>
                    {parse(t("trades_1_desc"))}
                  </p>
                </Content>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Risk Section */}
      <section className="history-section" id="MDR">
        <div className="auto-container ssp-top">
          <Content classes="text-center ">
            <div className="row justify-content-md-center">
              <div className="col-md-8">
                <div class="sec-title light">
                  <h2>{t("trades_2_title")}</h2>

                  <p style={{ textAlign: "justify" }}>
                    {parse(t("trades_2_desc"))}
                  </p>
                </div>
              </div>
            </div>
          </Content>
          <Tab.Container defaultActiveKey="second">
            <Nav className="nav-tabs tab-btn-style-one d-flex justify-content-center">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  {t("trades_risk_1_sub_title")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">
                  {t("trades_risk_2_sub_title")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="third">
                  {" "}
                  {t("trades_risk_3_sub_title")}
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first" className=" fadeInUp animated">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="content pl-lg-4">
                      <h2 className="text-white">{t("trades_risk_1_title")}</h2>

                      <div className="text">
                        {parse(t("trades_risk_1_desc"))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1">
                    <Thumbnail
                      classes="about-thumb"
                      imgSrc={require("../assets/img/service/camp.jpg")}
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="second" className=" fadeInUp animated">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="content pl-lg-4">
                      <h2 className="text-white">{t("trades_risk_2_title")}</h2>

                      <div className="text">
                        {parse(t("trades_risk_2_desc"))}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1">
                    <Thumbnail
                      classes="about-thumb"
                      imgSrc={require("../assets/img/service/dematrielisation.jpg")}
                    />
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="third" className=" fadeInUp animated">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="content pl-lg-4">
                      <h2 className="text-white">{t("trades_risk_3_title")}</h2>

                      <div className="text">
                        {parse(t("trades_risk_3_desc"))}
                      </div>
                      <div className="link-btn"></div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-5 offset-lg-1">
                    <Thumbnail
                      classes="about-thumb"
                      imgSrc={require("../assets/img/service/08.jpg")}
                    />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
      {ServicesData.map((service) =>
        service.id % 2 === 1 ? (
          <PageAbout
            key={service.id}
            id={service.link}
            title={t(service.title)}
            heading={t(service.shortDesc)}
            thumb={require("../assets/img/" + service.thumb)}
            content={t(service.shortDesc)}
            features={service.features}
          />
        ) : (
            <PageAboutReverse
              key={service.id}
              id={service.link}
              title={t(service.title)}
              heading={t(service.shortDesc)}
              thumb={require("../assets/img/" + service.thumb)}
              content={t(service.shortDesc)}
              features={service.features}
            />
          )
      )}

      <br />
      <br />
      <br />

      <Footer />
      <LoginRegister />
      <MobileMenu />
    </Fragment>
  );
};

export default PageService;
