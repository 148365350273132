import React from "react";
import parse from "html-react-parser";
import Thumbnail from "../../UI/Thumbnail";
import Content from "../../UI/Content";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import aboutData from "../../../data/About/home-one";

function About() {
  const { t } = useTranslation();
  return (
    <div className="about-area-wrapper sm-top">
      <div className="container">
        <div className="row align-items-lg-center">
          <div className="col-md-12 col-lg-5">
            <Thumbnail
              classes="about-thumb"
              imgSrc={require("../../../assets/img" + aboutData.thumb)}
            />
          </div>

          <div className="col-md-12 col-lg-7">
            <Content classes="about-content">
              <h6>{t(aboutData.title)}</h6>
              <h3>{parse(t(aboutData.heading))}</h3>
              <span className="about-since">{aboutData.since}</span>
              <p style={{ textAlign: "justify" }}>{t(parse(aboutData.text))}</p>
            </Content>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
