import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
const AnyReactComponent = ({ text }) => <div>{text}</div>;

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 37.00527,
      lng: 25.97696,
    },
    zoom: 1,
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: "400px", width: "100%" }}>
        <GoogleMapReact
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          bootstrapURLKeys={{ key: "AIzaSyAlv21NyxWkiIfWM95litAkIWnkKvE5zaE" }}
        >
          <Marker
            lat={48.856614}
            lng={2.3522219}
            text="Paris"
            desc="58 Avenue de Wagram 75017 Paris."
            title="JPG CONSULTING PARTNERS & ADVISORY"
          />
          <Marker
            lat={31.791702}
            lng={-7.09262}
            text="Maroc"
            desc="---"
            title="---"
            email="info-maroc@jpgconsulting-partners.com"
          />
          <Marker
            lat={38.71667}
            lng={-9.13333}
            text="Lisbonne"
            desc="---"
            title="---"
          />
          <Marker
            lat={25.761681}
            lng={-80.191788}
            text="Miami"
            desc="---"
            title="JPG CONSULTING PARTNERS USA LLC"
          />
          <Marker
            lat={46.818188}
            lng={8.227512}
            text="Genève"
            desc="Boulevard Georges Favon, 43, 1611 Genève"
            email="info@jpgconsulting-partners.com"
            tel=" (+41) 51 23 00 00"
          />
          <Marker
            lat={51.5073509}
            lng={-0.1277583}
            text="Londres"
            desc="Carlyle House, Lower Ground Floor, 235 Vauxhall Bridge Road"
            email="SW1V 1EJ | London | United Kingdom"
            title="JPG CONSULTING PARTNERS & FINANCIAL Services LLP "
          />
          <Marker
            lat={49.611621}
            lng={6.1319346}
            text="Luxembourg"
            desc="----"
            title="JPG CONSULTING PARTNERS & FUNDS COMPANY"
          />
          <Marker
            lat={25.2048493}
            lng={55.2707828}
            text="Dubaï"
            desc="Dubaï international Financial center "
            email="info-dubaï@jpgconsulting-partners.com"
            tel="2308 Prism Tower - DUBAÏ UAE"
            title="JPG CONSULTING PARTNERS & OIL COMPANY"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default SimpleMap;
