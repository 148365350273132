import React, { Component, Fragment } from "react";
import BlogItem from "../../components/Blog/blogItem";
import BlogItemList from "../../components/Blog/blogItemList";
import Pagination from "../../components/Pagination";

import BlogData from "../../data/Blog/blog";
import "moment/locale/fr";
import moment from "moment";
class BlogContent extends Component {
  state = {
    posts: [],
    currentPage: 1,
    postsPerPage: 9,
  };
  getItems() {
    fetch("https://www.jpgconsulting-partners.com/backend/public/annonces")
      .then((response) => response.json())
      .then((posts) => this.setState({ posts }))
      .catch((err) => console.log(err));
  }
  componentDidMount() {
    this.getItems();
  }

  render() {
    // Get current posts
    const indexOfLastPost = this.state.currentPage * this.state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - this.state.postsPerPage;
    const currentPosts = this.state.posts.slice(
      indexOfFirstPost,
      indexOfLastPost
    );

    // Change page
    const paginate = (currentPage) =>
      this.setState({
        currentPage: currentPage,
      });
    return (
      <div className={this.props.cols + " " + this.props.classes}>
        <Fragment>
          <div
            className={`blog-content-wrapper ${
              this.props.blog_type === "list" && "blog-list"
            }`}
          >
            <div className="row mtn-30">
              {this.props.blog_type === "list" ? (
                <div className={"col-12"}>
                  {currentPosts.reverse().map((annonce) => (
                    <BlogItemList
                      key={annonce.id}
                      id={annonce.id}
                      thumb="blog/08.jpg"
                      title={annonce.poste}
                      excerpt={annonce.description.slice(0, 100) + "..."}
                      postBy={moment(annonce.dateajout.date).format(
                        "Do MMM YYYY"
                      )}
                      date={annonce.datedeb.date}
                      localisation={annonce.localisation}
                    />
                  ))}
                </div>
              ) : (
                currentPosts
                  .reverse()
                  .map((annonce) => (
                    <BlogItem
                      key={annonce.id}
                      id={annonce.id}
                      cols={
                        this.props.cols === "col-12"
                          ? "col-md-6 col-lg-4"
                          : "col-md-6"
                      }
                      thumb="blog/08.jpg"
                      title={annonce.poste}
                      excerpt={annonce.description}
                      postBy={moment(annonce.dateajout.date).format(
                        "Do MMM YYYY"
                      )}
                      date={annonce.datedeb.date}
                      localisation={annonce.localisation}
                    />
                  ))
              )}
            </div>
          </div>

          <Pagination
            postsPerPage={this.state.postsPerPage}
            totalPosts={this.state.posts.length}
            paginate={paginate}
            currentPage={this.state.currentPage}
          />
        </Fragment>
      </div>
    );
  }
}

export default BlogContent;
