import React from "react";
import SectionTitle from "../UI/SectionTitle";
import ServiceItem from "./ServiceItem";
import ServicesData from "../../data/Services/services";
import { useTranslation } from "react-i18next";
import serviceTopBg from "../../assets/img/service/service-bg.jpg";

function Services({ classes }) {
  const { t } = useTranslation();
  return (
    <div className={`service-area-wrapper ${classes}`}>
      <div
        className="service-area-top"
        style={{ backgroundImage: `url("${serviceTopBg}")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 m-auto text-center">
              <SectionTitle
                variant="light"
                title={t("home_service_title")}
                heading={t("home_service_descreption")}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="service-content-area">
        <div className="container">
          <div className="row mtn-30">
            {ServicesData.map(
              (service) =>
                service.id != 12 && (
                  <ServiceItem
                    key={service.id}
                    id={service.id}
                    title={t(service.title)}
                    text={service.shortDesc}
                    thumb={service.thumb}
                  />
                )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
