import React from "react";
import "./style.css";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.svg";
import {
  Form,
  Button,
  Col,
  Row,
  Input,
  Select,
  DatePicker,
  message,
} from "antd";

import { connect } from "react-redux";
import authAction from "../../redux/auth/actions";
import annonceActions from "../../redux/annonces/actions";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UploadOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "moment/locale/fr";
import moment from "moment";

const key = "updatable";
const { Option } = Select;
const { Header, Sider, Content } = Layout;

const layout = {
  //labelCol: { span: 4 },
};
class SiderDemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      poste: "",
      localisation: "",
      datedeb: "",
      description: "",
      salaire: "",
      experience: "",
      fiche: "",
      taches: "",
      typedemlpoi: "",
      posted: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  onChangeselect = (value) => {
    this.setState({ typedemlpoi: value });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  handleChange(e) {
    this.setState({ [e.target.id]: e.target.value });
    // console.log(e.target.value);
  }

  onClick = () => {
    this.openMessageadd();
  };
  openMessageadd = () => {
    message.loading({ content: "Chargement...", key });
    setTimeout(() => this.putannonce(), 3000);
    message.success({
      content: " Votre annonce est à jour ",
      key,
      duration: 2,
    });
  };

  onChange = (date, dateString) => {
    this.setState({ datedeb: dateString });
  };
  onChangeinput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    //console.log(e.target.value);
  };

  putannonce = () => {
    this.props.putannonce({
      id: this.props.match.params.annonceID,
      poste: this.state.poste,
      localisation: this.state.localisation,
      datedeb: this.state.datedeb,
      description: this.state.description,
      salaire: this.state.salaire,
      experience: this.state.experience,
      fiche: this.state.fiche,
      taches: this.state.taches,
      typedemlpoi: this.state.typedemlpoi,
    });
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  renderannonce = () => {
    !this.props.load &&
      this.setState({
        id: this.props.match.params.annonceID,
        poste: this.props.annonce.poste,
        localisation: this.props.annonce.localisation,
        datedeb: this.props.annonce.datedeb.date,
        description: this.props.annonce.description,
        salaire: this.props.annonce.salaire,
        experience: this.props.annonce.experience,
        fiche: this.props.annonce.fiche,
        taches: this.props.annonce.taches,
        typedemlpoi: this.props.annonce.typemlpoi,
        posted: true,
      });
    console.log(this.props.annonce.datedeb.date, "test");
    //
  };
  renderscroll = () => {
    !this.props.load && window.location.reload();
    //
  };

  componentDidMount() {
    this.props.getannonce(this.props.match.params.annonceID);
    //window.location.reload();
  }

  render() {
    //console.log("this.props",this.props)
    return (
      <Layout style={{ minHeight: "1000px" }}>
        <Sider trigger={null} collapsible collapsed={this.state.collapsed}>
          <div className="logo">
            <img
              src={logo}
              alt="JPG-Logo"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            />
          </div>

          <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item
              key="1"
              icon={<UploadOutlined />}
              onClick={() => this.props.history.push("/gannonces")}
            >
              Les annonces
            </Menu.Item>
            <Menu.Item
              key="2"
              icon={<UserOutlined />}
              onClick={this.props.logout}
            >
              Déconnexion
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ minHeight: "1100px" }}>
          <Header
            className="site-layout-background"
            style={{ paddingRight: 0 }}
          >
            {React.createElement(
              this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: this.toggle,
              }
            )}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "24px 16px",
              padding: 24,
              //   minHeight: 1100,
              // overflow: 'scroll'
            }}
          >
            <br />
            <br />

            {!this.props.load && (
              <Form layout="vertical" {...layout}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col
                    className="gutter-row"
                    span={{ xs: 24, sm: 24, md: 16, lg: 16 }}
                  >
                    <Form.Item label="Poste:" labelCol={{ span: 4 }}>
                      <Input
                        id="poste"
                        name="poste"
                        value={this.state.poste}
                        onChange={this.handleInputChange}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={{ xs: 24, sm: 8, md: 8, lg: 8 }}
                    offset={1}
                  >
                    <Form.Item label="Localisation:">
                      <Input
                        name="localisation"
                        id="localisation"
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        //defaultValue={this.props.annonce.localisation}
                        value={this.state.localisation}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col
                    className="gutter-row"
                    span={{ xs: 24, sm: 8, md: 8, lg: 8 }}
                  >
                    <Form.Item label="Experience :">
                      <Input
                        name="experience"
                        id="experience"
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        value={this.state.experience}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    span={{ xs: 24, sm: 8, md: 8, lg: 8 }}
                    offset={1}
                  >
                    <Form.Item label="Salaire :">
                      <Input
                        name="salaire"
                        id="salaire"
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        //defaultValue={this.props.annonce.localisation}
                        value={this.state.salaire}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row">
                    <Form.Item label="Date de debut :">
                      <DatePicker
                        name="datedeb"
                        id="datedeb"
                        onChange={this.onChange}
                        //{moment(this.props.annonce.datedeb.date).format("Do MMM YY")}

                        value={moment(this.state.datedeb)}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" lg={{ span: 4, offset: 1 }}>
                    <Form.Item label="Type d'emlpoi :">
                      <Select
                        name="typedemlpoi"
                        id="typedemlpoi"
                        onChange={this.onChangeselect}
                        value={this.state.typedemlpoi}
                      >
                        <Select.Option value="CDI">CDI</Select.Option>
                        <Select.Option value="CDI de chantier">
                          CDI de chantier
                        </Select.Option>
                        <Select.Option value="CDD">CDD</Select.Option>

                        <Select.Option value="Stage">Stage</Select.Option>
                        <Select.Option value="Freelance">
                          Freelance
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" flex={2}>
                    <Form.Item label="Description :" labelCol={{ span: 6 }}>
                      <Input.TextArea
                        name="description"
                        id="description"
                        rows={4}
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        value={this.state.description}
                      />
                    </Form.Item>
                  </Col>

                  <Col flex={3}></Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" flex={2}>
                    <Form.Item label="Fiche :" labelCol={{ span: 4 }}>
                      <Input.TextArea
                        name="fiche"
                        id="fiche"
                        rows={4}
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        value={this.state.fiche}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex={3}></Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col className="gutter-row" flex={2}>
                    <Form.Item label="Tâches :" labelCol={{ span: 4 }}>
                      <Input.TextArea
                        name="taches"
                        id="taches"
                        rows={4}
                        onChange={
                          (this.handleChange = this.handleChange.bind(this))
                        }
                        value={this.state.taches}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex={3}></Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col
                    className="gutter-row"
                    span={{ xs: 24, sm: 24, md: 16, lg: 16 }}
                    offset={8}
                  >
                    <Form.Item>
                      {!this.props.load &&
                        !this.state.posted &&
                        (setTimeout(() => {
                          this.renderannonce();
                        }, 1000),
                        (
                          <Button
                            type="primary"
                            style={{ float: "right" }}
                            onClick={this.onClick}
                          >
                            mettre à jour
                          </Button>
                        ))}
                      <Button
                        type="primary"
                        style={{ float: "right" }}
                        onClick={this.onClick}
                      >
                        mettre à jour
                      </Button>
                      <Button
                        type="danger"
                        style={{ float: "right", marginRight: "10px" }}
                        // onClick={()=>{this.props.push("/gannonces")}}
                        onClick={() => this.props.history.push("/gannonces")}
                      >
                        Annuler
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Content>
        </Layout>
      </Layout>
    );
  }
}
const { getallannonces, putannonce, getannonce } = annonceActions;
const { logout } = authAction;
function mapStateToProps(state) {
  const { annonces, loading, annonce, load } = state.Annonce.toJS();
  const { isAuthenticated, user } = state.Auth.toJS();
  return {
    annonces,
    loading,
    isAuthenticated,
    user,
    annonce,
    load,
  };
}

export default connect(mapStateToProps, {
  getallannonces,
  putannonce,
  logout,
  getannonce,
})(SiderDemo);
